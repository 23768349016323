<header>
  <div class="header-container">
    <span>Valora</span>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Logo"
      viewBox="0 0 135 51"
      width="80"
      height="32">
      <defs>
        <style>
          .cls-1 { fill: #3583c5; }
          .cls-2 { fill: #de2681; }
          .cls-3 { fill: #11b6ce; }
          .cls-4 { fill: #2ea14a; }
          .cls-5 { fill: #fdca2e; }
          .cls-6 { fill: #c8d762; }
          .cls-7 { fill: #fff; isolation: isolate; opacity: .25; }
          .cls-8 { fill: #b91f3c; }
          .cls-9 { fill: #fff; }
          .cls-10 { fill: #e94c35; }
        </style>
      </defs>
      <g id="Layer">
        <g id="Layer1">
          <path class="cls-9 logo-elem-1" d="m19.13,15.12h-4.97l.58-5.46v-.12c0-.84-.37-2.77-3.8-2.77h-.28c-3.17,0-3.87,1.61-3.98,2.61l-.7,5.74h-3c-1.1,0-2.98.55-2.98,4.28,0,2.88,1.86,3.1,2.44,3.1h2.77l-2.2,21.41v.13c0,5.52,3.08,8.57,8.68,8.57,4.11,0,5.39-1.74,5.75-3.19.22-.82.34-1.66.37-2.51,0-1.7-1.12-2.14-1.78-2.14-.44.04-.86.13-1.27.28-.55.14-1.12.2-1.69.19-.93,0-1.84-.19-1.84-2.43,0-.55.37-4.55,2.09-20.29h3.9c1.2-.03,2.32-.6,3.05-1.56.78-.9,1.22-2.06,1.22-3.25.14-1.27-.78-2.42-2.04-2.57-.11-.01-.21-.02-.32-.02Z"></path>
          <path class="cls-9 logo-elem-2" d="m45.1,14.73c-6.51,0-11.43,2.2-14.65,6.56s-4.73,9.78-4.73,16.37c0,9.78,5.58,14.96,16.16,14.96,2.4-.04,4.79-.43,7.08-1.15,2.98-.87,4.65-2.27,4.98-4.13.2-.99.32-1.99.35-2.99,0-2.22-1.48-2.33-1.78-2.33s-.88.16-2.25,1c-2.4,1.45-5.16,2.2-7.96,2.18-2.78,0-4.87-.62-6.27-1.82-1.2-1-1.86-2.88-1.99-5.47,1.43.17,2.87.24,4.31.23,4.39.11,8.75-.81,12.73-2.7,1.92-.95,3.54-2.42,4.65-4.26,1.13-1.92,1.71-4.12,1.66-6.35-.04-2.66-1.16-5.18-3.1-7-2.01-2.03-5.12-3.11-9.2-3.11h0Zm-.59,7.37c1.23-.08,2.45.27,3.45,1,.71.51,1.13,1.33,1.13,2.2.02.89-.29,1.75-.87,2.43-.59.72-1.33,1.29-2.17,1.69-2.66,1-5.49,1.46-8.32,1.35h-3.09c1.21-5.81,4.44-8.67,9.87-8.67Z"></path>
          <path class="cls-9 logo-elem-3" d="m87.54,15.96c-2.29-.89-4.74-1.31-7.19-1.23-3.89-.12-7.71,1.07-10.85,3.38-2.81,2.11-4.93,5-6.1,8.32-1.22,3.75-1.8,7.68-1.72,11.63,0,4.77,1.34,8.43,3.98,10.88,2.63,2.45,6.49,3.65,11.54,3.65,2.07-.02,4.13-.25,6.16-.69,2.63-.56,4.14-1.7,4.47-3.38.21-.98.33-1.99.35-2.99,0-2.22-1.31-2.38-1.71-2.38s-.5,0-1.79.58c-2.2.99-4.6,1.49-7.01,1.46-2.72,0-4.68-.63-5.84-1.87s-1.78-3.34-1.78-6.27c0-5.3.91-9.17,2.73-11.55,1.48-2.15,3.93-3.43,6.54-3.4,2.79,0,4.86.43,6.19,1.31.8.51,1.07.67,1.5.67,1.16-.14,2.18-.82,2.76-1.84.65-.78,1.04-1.75,1.12-2.76,0-2.05-2.5-3.15-3.33-3.52h-.02Z"></path>
          <path class="cls-10 logo-elem-4" d="m109.15,16.62c-.95.55-1.84,1.19-2.66,1.92-4.66,3.19-8.04,7.93-9.55,13.37l-1.24,11.61c2.58-1.37,5.45-2.11,8.37-2.15l1-9.75c1.22-3.45,2.7-5.92,4.4-7.34.71-.6,1.49-1.11,2.33-1.52.58-2.41-.5-4.92-2.65-6.15h0Z"></path>
          <path class="cls-8 logo-elem-5" d="m95.1,49.51v.14c0,.82.37,2.7,3.82,2.7h.26c3.23,0,3.98-1.62,4.07-2.63l.87-8.35c-2.94.04-5.82.77-8.41,2.15l-.61,5.98h0Z"></path>
          <path class="cls-3 logo-elem-6" d="m125.91,28l.32-3.27v-.13c0-6.46-3.46-9.88-9.95-9.88-2.49-.01-4.94.64-7.09,1.88,2.17,1.22,3.27,3.73,2.7,6.15.75-.37,1.58-.57,2.42-.59,2.61,0,3.68,1.09,3.68,3.73,0,.13-.05.66-.22,2.36,2.66.84,5.53.74,8.13-.27v.02Z"></path>
          <path class="cls-4 logo-elem-7" d="m127.16,44.9l-1.89.34c-.23,0-.45-.06-.67-.14-1,1.17-2.08,3.39-2.4,7.46.38.04.76.07,1.14.07,1.03,0,2.04-.22,2.98-.63,1.22-.44,2.11-1.51,2.33-2.8.15-.74.23-1.49.25-2.24.14-.97-.53-1.88-1.5-2.02h0c-.08-.02-.16-.03-.25-.04h0Z"></path>
          <path class="cls-1 logo-elem-8" d="m125.91,28c-2.61,1.03-5.5,1.13-8.17.29-.14,1.35-.36,3.43-.7,6.63-.69,6.41-.87,8.6-.91,9.42,2.54-1.82,5.45-3.05,8.53-3.62l1.26-12.73h-.01Z"></path>
          <path class="cls-6 logo-elem-9" d="m124.55,45.04c-.13-.37-.19-.76-.17-1.15l.32-3.19c-3.07.57-5.98,1.8-8.52,3.62v.5c0,4.59,2.15,7.28,6.09,7.71.32-4.07,1.43-6.3,2.4-7.46l-.11-.03h-.01Z"></path>
          <path class="cls-2 logo-elem-10" d="m107.23,11.62l.93-8.67v-.14c0-.84-.37-2.77-3.82-2.77h-.27c-3.22,0-3.98,1.63-4.07,2.63l-.45,4.31c2.04,2.27,4.71,3.89,7.67,4.64h0Z"></path>
          <path class="cls-5 logo-elem-11" d="m106.5,18.54l.74-6.92c-2.95-.75-5.62-2.37-7.66-4.64l-2.63,24.93c1.51-5.45,4.89-10.19,9.55-13.37Z"></path>
          <path class="cls-7 logo-elem-12" d="m97.7,49.65v-.14l4.92-46.88c.12-.87.67-2.19,2.87-2.54-.39-.06-.78-.09-1.17-.09h-.27c-3.22,0-3.98,1.63-4.07,2.63l-4.97,46.88v.14c0,.82.37,2.7,3.82,2.7h.26c.4,0,.8-.03,1.19-.09-2.27-.38-2.59-1.91-2.59-2.61h0Z"></path>
          <path class="cls-7 logo-elem-13" d="m118.75,44.84c0-.32,0-1.27.93-9.92.78-7.28.9-8.76.91-8.98,0-2.65-1.07-3.73-3.67-3.73-.41,0-.82.06-1.22.16,1.57.38,2.26,1.49,2.26,3.58,0,.23-.13,1.71-.92,8.98-.93,8.66-.93,9.6-.93,9.92,0,4.99,2.56,7.78,7.23,7.78.39,0,.78-.04,1.16-.11-3.73-.56-5.76-3.23-5.76-7.68h0Z"></path>
        </g>
      </g>
    </svg>
  </div>
</header>

import {AbstractControl} from '@angular/forms';

/**
 * Method to check if a control has errors
 *
 * @param control AbstractControl | null
 * @param showErrors showErrors: boolean | null
 */
export function hasControlErrors(control: AbstractControl | null, showErrors: boolean | null) {
  let hasErrors = false;

  if (control && control.errors && (control.touched || control.dirty || showErrors)) {
    hasErrors = true;
  }

  return hasErrors;
}

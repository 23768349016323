import {Component} from '@angular/core';

// Enums
import {SurveyStatusEnum} from "@app/enums/survey-status.enum";
import {apiResponseMock} from "@app/mocks/response-mock";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent {

  public data = apiResponseMock;
  public isProcessing = false;
  public hasErrors = false;
  public isFormValid = false;

  private formData?: any;

  /**
   * Method to get the form data when the event is dispatched
   *
   * @param data any
   */
  public onFormDataChange(data: any): void {
    this.formData = data;
  }

  /**
   * Method to get the form status when the event is dispatched
   *
   * @param isValid boolean
   */
  public onFormValidChange(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  /**
   * Method to cancel and redirect to back route
   */
  public onCancel(): void {
    console.log('-- CANCEL --')
  }

  /**
   * Method to cancel and redirect to back route
   */
  public onSubmit(): void {
    if (!this.isFormValid) {
      console.log('-- FORM WITH ERRORS --')

      this.hasErrors = true;
      return;
    }

    console.log('-- SUBMIT --')
    console.log(this.formData)
  }

  protected readonly StatusEnum = SurveyStatusEnum;
}

import {IQuery} from "@app/interfaces/question-interface";

export enum QuestionTypeEnum {
    CLOSE = "Cerrada", // Select
    MULTIPLE = "Opción múltiple", // Select Multiple
    SCALE = "Escala de valoración", // Radio
    OPEN = "Abierta" // Textarea
}

export const apiResponseMock: IQuery = {
    "idSurvey": 1,
    "title": "Encuesta de Evaluación de Desempeño",
    "description": "Evaluación de desempeño anual de empleados",
    "creationDate": "2024-11-14T12:48:45.103",
    "status": "Activo",
    "questions": [
    {
        "idQuestion": 1,
        "statement": "¿Está satisfecho con el desempeño del empleado?",
        "minValue": null,
        "maxValue": null,
        "questionTypeName": "Cerrada",
        "hasPredefinedOptions": true,
        "isRequired": true,
        "order": 1,
        "questionOptions": [
            {
                "idQuestionOption": 1,
                "optionText": "Sí",
                "optionValue": "Sí"
            },
            {
                "idQuestionOption": 2,
                "optionText": "No",
                "optionValue": "No"
            }
        ]
    },
    {
        "idQuestion": 20,
        "statement": "¿Qué habilidades valora más en el empleado?",
        "minValue": null,
        "maxValue": null,
        "questionTypeName": "Opción múltiple",
        "hasPredefinedOptions": true,
        "isRequired": true,
        "order": 2,
        "questionOptions": [
            {
                "idQuestionOption": 30,
                "optionText": "Comunicación",
                "optionValue": "Comunicación"
            },
            {
                "idQuestionOption": 41,
                "optionText": "Trabajo en equipo",
                "optionValue": "Trabajo en equipo"
            },
            {
                "idQuestionOption": 51,
                "optionText": "Liderazgo",
                "optionValue": "Liderazgo"
            }
        ]
    },
    {
        "idQuestion": 3,
        "statement": "Califique del 1 al 5 el desempeño del empleado.",
        "minValue": 1,
        "maxValue": 5,
        "questionTypeName": "Escala de valoración",
        "hasPredefinedOptions": false,
        "isRequired": true,
        "order": 3,
        "questionOptions": []
    },
    {
        "idQuestion": 4,
        "statement": "¿Recomendaría al empleado para un ascenso?",
        "minValue": null,
        "maxValue": null,
        "questionTypeName": "Cerrada",
        "hasPredefinedOptions": true,
        "isRequired": true,
        "order": 4,
        "questionOptions": [
            {
                "idQuestionOption": 6,
                "optionText": "Sí",
                "optionValue": "Sí"
            },
            {
                "idQuestionOption": 7,
                "optionText": "No",
                "optionValue": "No"
            }
        ]
    },
    {
        "idQuestion": 5,
        "statement": "Indique la satisfacción en una escala visual del 1 al 10.",
        "minValue": 1,
        "maxValue": 10,
        "questionTypeName": "Escala de valoración",
        "hasPredefinedOptions": false,
        "isRequired": true,
        "order": 5,
        "questionOptions": []
    },
    {
        "idQuestion": 6,
        "statement": "¿Cuán probable es que recomiende a este empleado a otra área?",
        "minValue": 0,
        "maxValue": 10,
        "questionTypeName": "Escala de valoración",
        "hasPredefinedOptions": false,
        "isRequired": true,
        "order": 6,
        "questionOptions": []
    },
    {
        "idQuestion": 7,
        "statement": "¿Cuál es la antigüedad del empleado en la empresa (en años)?",
        "minValue": null,
        "maxValue": null,
        "questionTypeName": "Abierta",
        "hasPredefinedOptions": false,
        "isRequired": true,
        "order": 7,
        "questionOptions": []
    },
    {
        "idQuestion": 8,
        "statement": "¿Qué aspectos considera que el empleado debe mejorar?",
        "minValue": null,
        "maxValue": null,
        "questionTypeName": "Abierta",
        "hasPredefinedOptions": false,
        "isRequired": true,
        "order": 8,
        "questionOptions": []
    }
]
}

<tech-card class="tech--w-full tech--p-2">
  <tech-card-header class="tech-container__header">
    <h1 class="tech-container__header__title">
      #{{ data.idSurvey }} - {{ data.title | uppercase }}
    </h1>
    <span class="status">
      {{ 'common.status' | translate | sentenceCase }}:
      <strong [ngClass]="{'active': data.status === StatusEnum.active, 'inactive': data.status === StatusEnum.inactive}">
        {{ data.status|uppercase }}
      </strong>
    </span>
  </tech-card-header>
  <tech-card-content>
    <p class="tech-container__header__subtitle">
      {{ data.description }}
    </p>

    @if (data) {
      <app-survey-form
        [isLoading]="true"
        [formData]="data.questions"
        [showErrors]="false"
        (formDataEvent)="onFormDataChange($event)"
        (isValidFormEvent)="onFormValidChange($event)">
      </app-survey-form>
    }

    <div class="tech-container__footer">
      <button
        techButton
        techButtonColor="secondary"
        [disabled]="isProcessing" (click)="onCancel()">
        {{ 'buttons.cancel' | translate | uppercase }}
      </button>
      <button
        techButton
        techButtonColor="primary"
        [disabled]="data.status === StatusEnum.inactive || !isFormValid"
        (click)="onSubmit()">
        {{ 'buttons.send' | translate | uppercase }}
      </button>
    </div>
  </tech-card-content>

  <p class="creation-date">{{ 'common.created' | translate | sentenceCase }}: <span>{{ data.creationDate | date:'fullDate' }}</span></p>
</tech-card>

<pre class="pre" [hidden]="false">
Ejemplo de datos:
{{ data|json }}
</pre>

<div class="app" techScrollbars>
  <app-header/>

  <main class="main-container">
    <div class="app-grid rows-content">
      <app-survey/>
    </div>

    <router-outlet/>
  </main>

  <app-footer/>
</div>
